import { FC, lazy, Suspense } from 'react';
import Loader from '../Loader';
import IconProps from './IconProps';

export enum PlatformIcons {
  ACCOUNTABILITY_ICON = 'AccountabilityIcon',
  AUDIT_ICON = 'AuditIcon',
  CONSENT_MANAGEMENT_ICON = 'ConsentManagementIcon',
  DATABASE = 'DatabaseIcon',
  DATA_BREACH_RESPONSE = 'DataBreachResponseIcon',
  DATA_MINIMIZATION_ICON = 'DataMinimizationIcon',
  DATA_SUBJECT_RIGHTS_ICON = 'DataSubjectRightsIcon',
  DATA_TRANSFER_CONTROLS = 'DataTransferControlsIcon',
  FORM = 'FormIcon',
  GENERAL_ENQUIRY = 'GeneralEnquiryIcon',
  HANDSHAKE = 'HandshakeIcon',
  INTERNET_SOCIAL = 'InternetSocialMediaIcon',
  INVENTORY_ICON = 'InventoryIcon',
  IT_APPLICATION = 'ITApplicationIcon',
  NEWS_AND_UPDATES = 'NewsAndUpdatesIcon',
  PRIVACY_BY_DESIGN_ICON = 'PrivacyByDesignIcon',
  PROCESSING_ACTIVITY = 'ProcessingActivityIcon',
  PRODUCT = 'ProductIcon',
  SCALE = 'ScaleIcon',
  SECURITY_ICON = 'SecurityIcon',
  SERVICE = 'ServiceIcon',
  SETTINGS_ICON = 'SettingsIcon',
  TEMPLATES = 'TemplateIcon',
  THIRDPARTIES_ICON = 'ThirdPartiesIcon',
  TRAINING_AWARENESS_ICON = 'TrainingAwarenessIcon',
  TRANSPARENCY_INFORMATION_ICON = 'TransparencyInformationIcon',
  WEBSITE = 'WebsiteIcon',
  WRENCH = 'WrenchIcon',
}

type IconRenderProps = {
  type: string;
} & IconProps;

// Cache the lazily loaded icons in a variable so they don't get lazy loaded EVERY render.
const IconMap: Record<string, FC<IconProps>> = {};
const loadIcon = (type: string) => {
  if (!IconMap[type]) {
    IconMap[type] = lazy(() => {
      try {
        // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
        return Promise.resolve(import(`./${(type || PlatformIcons.AUDIT_ICON).replace(/Icon$/, '')}Icon.tsx`) || { default: () => null });
      } catch {
        return Promise.resolve({ default: () => null });
      }
    });
  }

  return IconMap[type];
};

const IconRender: FC<IconRenderProps> = (props) => {
  const { type, ...restProps } = props;
  const Icon = loadIcon(type);

  if (!Icon) {
    console.warn("Couldn't render icon ", type);
    return null;
  }

  return (
    <Suspense fallback={<Loader centered={false} size={6} />}>
      <Icon {...restProps} />
    </Suspense>
  );
};

export default IconRender;
